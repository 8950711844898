<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "ImSelect",
  
})
</script>
<template>
  <a-select
    class="im-select w-51"
    v-bind="$attrs"
    :getPopupContainer="getPopupContainer"
    show-search
    option-filter-prop="label"
  >
    <slot />
  </a-select>
</template>

<script setup lang="ts" name="ImSelect">
const getPopupContainer = (triggerNode: any) => triggerNode.parentNode
</script>
