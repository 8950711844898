import { defineStore } from 'pinia'
import type { BaseObject } from '@/types/interface/base'

export interface CommonKeepState {
  filters: BaseObject<any> | null,
  pagination: BaseObject<any> | null,
}

export interface FilterKeepState {
  businessKeepData: CommonKeepState | null
  chargebackKeepData: CommonKeepState | null
  billReissueKeepData: CommonKeepState | null
  billKeepData: CommonKeepState | null
  dataAnalyzeKeepData: CommonKeepState | null
  payingUsersKeepData: CommonKeepState | null
  payChannelKeepData: CommonKeepState | null
  conversionRateKeepData: CommonKeepState | null
  propAnalysisKeepData: CommonKeepState | null
  rateFloatKeepData: CommonKeepState | null
  activitiesDataKeepData: CommonKeepState | null
  refundAnalyzeKeepData: CommonKeepState | null
  channelAnalyseKeepData: CommonKeepState | null
  channelOverviewKeepData: CommonKeepState | null
  areaAnalyzeKeepData: CommonKeepState | null
  areaOverviewKeepData: CommonKeepState | null
  currencyAnalyzeKeepData: CommonKeepState | null
  currencyOverviewKeepData: CommonKeepState | null
  userPayTopKeepData: CommonKeepState | null
  goodSalesTopKeepData: CommonKeepState | null
  vipDataKeepData: CommonKeepState | null
  signInDataKeepData: CommonKeepState | null
  integTaskDataKeepData: CommonKeepState | null
  integDoodsDataKeepData: CommonKeepState | null
  promoterDataKeepData: CommonKeepState | null
}

export const UseFilterKeep = defineStore('useFilterKeep', {
  state: (): FilterKeepState => ({
    businessKeepData: null,
    chargebackKeepData: null,
    billReissueKeepData: null,
    billKeepData: null,
    dataAnalyzeKeepData: null,
    payingUsersKeepData: null,
    payChannelKeepData: null,
    conversionRateKeepData: null,
    propAnalysisKeepData: null,
    rateFloatKeepData: null,
    activitiesDataKeepData: null,
    refundAnalyzeKeepData: null,
    channelAnalyseKeepData: null,
    channelOverviewKeepData: null,
    areaAnalyzeKeepData: null,
    areaOverviewKeepData: null,
    currencyAnalyzeKeepData: null,
    currencyOverviewKeepData: null,
    userPayTopKeepData: null,
    goodSalesTopKeepData: null,
    vipDataKeepData: null,
    signInDataKeepData: null,
    integTaskDataKeepData: null,
    integDoodsDataKeepData: null,
    promoterDataKeepData: null
  }),

  actions: {
    setData(name: keyof FilterKeepState, data: Partial<CommonKeepState>) {
      if (!this[name]) {
        this[name] = {
          filters: null,
          pagination: null,
        }
      }
      if (this && name && this[name]) {
        if (data?.filters) {
          (this[name] as CommonKeepState).filters = data.filters
        }
        if (data?.pagination) {
          (this[name] as CommonKeepState).pagination = data.pagination
        }
      }
    },

    clearData(name: keyof FilterKeepState) {
      this[name] = null
    },

    getData(name: keyof FilterKeepState) {
      return this[name]
    },

    clearAllData() {
      this.businessKeepData = null
      this.chargebackKeepData = null
      this.billReissueKeepData = null
      this.billKeepData = null
      this.dataAnalyzeKeepData = null
      this.payingUsersKeepData = null
      this.payChannelKeepData = null
      this.conversionRateKeepData = null
      this.propAnalysisKeepData = null
      this.rateFloatKeepData = null
      this.activitiesDataKeepData = null
      this.refundAnalyzeKeepData = null
      this.channelAnalyseKeepData = null
      this.channelOverviewKeepData = null
      this.areaAnalyzeKeepData = null
      this.areaOverviewKeepData = null
      this.currencyAnalyzeKeepData = null
      this.currencyOverviewKeepData = null
      this.userPayTopKeepData = null
      this.goodSalesTopKeepData = null
      this.vipDataKeepData = null
      this.signInDataKeepData = null
      this.integTaskDataKeepData = null
      this.integDoodsDataKeepData = null
      this.promoterDataKeepData = null
    },
  },
})
