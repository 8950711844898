<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "KopIcon",
  
})
</script>
<template>
  <picture-filled
    v-if="props.type == 'pictureFilled'"
    :class="['kop_icon', 'picture-icon', props.hover ? 'is_hover' : '']"
    @click="handleClick"
  />
  <icon-font
    v-if="props.type != 'pictureFilled'"
    :type="props.type"
    :class="['kop_icon', props.type, props.hover ? 'is_hover' : '']"
    v-bind="$attrs"
    @click="handleClick"
  />
</template>

<script setup lang="ts" name="KopIcon">
import { createFromIconfontCN, PictureFilled } from '@ant-design/icons-vue'
const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3983864_j28cd6926om.js',
})

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  hover: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click'])
const handleClick = () => {
  emit('click')
}
</script>

<style lang="less" scoped>
.is_hover {
  color: #adaeb5;

  &:hover {
    color: #3d46f5;
  }
}

.kop_btn_icon {
  color: unset;
}
.picture-icon {
  font-size: 20px !important;
}
</style>
