import { operationLogApi } from "@/api/common"
import { useAuthStore } from "@/stores/modules/authStore"
import { ProjectStore } from "@/stores/modules/project"
import type { OperationLogParams } from "@/types/interface/operationRecord"
import { storeToRefs } from "pinia"

export function useOperatorLog(routeName: string) {
  const authStore = useAuthStore()
  const projectStore = ProjectStore()

  const getOperatorLogQueryParams = async (): Promise<OperationLogParams> => {
    try {
      const menu_no = await authStore.getMenuNoByName(routeName)
      const projectNoObj = projectStore.projectNo
        ? { project_no: projectStore.projectNo }
        : {}
      return {
        menu_no,
        ...projectNoObj,
      }
    } catch (error) {
      console.error(error)
      return {}
    }
  }

  const operationLogRequest = async (oper_name: string, description = '') => {
    const menu_no = await authStore.getMenuNoByName(routeName)
    const { projectNo, projectId } = storeToRefs(projectStore)
    return operationLogApi({
      menu_no,
      project_no: projectNo.value,
      project_id: projectId.value,
      oper_name,
      description,
    })
  }

  return {
    getOperatorLogQueryParams,
    operationLogRequest,
  }
}
