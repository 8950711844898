import { getPrmAuthMenuApi, getPrmRolesListApi } from "@/api/permissionsApi";
import { asyncProjectRoutesList } from "@/router/list/project";
import { asyncTopRoutesList } from "@/router/list/top";
import type { AuthItem, PrmRoleItem } from "@/types/interface/auth";
import type { BaseLabelValueArray, BaseObject } from "@/types/interface/base";
import type { TreeProps } from "ant-design-vue";
import type { DataNode } from "ant-design-vue/lib/tree";
import { cloneDeep } from "lodash-es";
import { defineStore } from "pinia"
import type { RouteRecordRaw } from "vue-router";

/**
 * 生成树形菜单
 *
 * @param list 原始路由数据
 * @param noNameMap 路由的 menu_no数据
 * @param disabledKeys 树形菜单可拥有禁用的属性
 * @param isFilter 是否要过滤到不需要选的菜单
 * @returns 
 */
function generateFormatMenuData(list: RouteRecordRaw[], noNameMap: BaseObject<string>, disabledKeys: string[], isFilter = true): TreeProps['treeData'] {
  if (!list?.length) {
    return []
  }
  if (isFilter) {
    list = list.filter(r => !r.meta?.isNotSelect)
  }
  return list.map(r => {
    const key = noNameMap[r.name as string] || r.name as string
    const permissions = (r?.meta?.actions_permissions as any[] || []).map?.(p => {
      const ckey = noNameMap[p.name as string] || p.name;
      return {
        title: p.title,
        key: ckey,
        disableCheckbox: disabledKeys.includes(ckey),
        children: [],
      }
    })
    return {
      title: r?.meta?.title,
      key,
      disableCheckbox: disabledKeys.includes(key),
      children: r.meta?.isHideChildren
        ? []
        : [
          ...permissions,
          ...(generateFormatMenuData(r.children as RouteRecordRaw[], noNameMap, disabledKeys, isFilter) as any[]),
        ]
    }
  })
}

/**
 * 根据树形结构，拿到所有可展开项
 *
 * @param list 
 * @returns 
 */
function getExpandKeys(list: TreeProps['treeData']): string[] {
  if (!list?.length || !Array.isArray(list)) {
    return []
  }
  return list.reduce((a: string[], v: DataNode) => {
    a.push(v.key as string)
    a.push(...(v.children ? getExpandKeys(v.children) : []))
    return a
  }, [])
}

export const useAuthStore = defineStore('auth-store', {
  state: () => ({
    allProjectData: [] as AuthItem[],
    allMenuData: [] as AuthItem[],
    asyncMenuData: [
      {
        path: '',
        name: 'TOP',
        meta: {
          title: '系统菜单'
        },
        children: cloneDeep(asyncTopRoutesList)
      },
      {
        path: '',
        name: 'PROJECT',
        meta: {
          title: '应用菜单'
        },
        children: cloneDeep(asyncProjectRoutesList)
      }
    ],
    formatMenuData: [],
    allRoleOptions: [] as BaseLabelValueArray<string, string>,
  }),

  getters: {
    /**
     * {
     *  ANALYZE: '1646453323374530560',
     *  [name1]: [menu_no1]
     * }
     * 
     * @param state 
     * @returns 
     */
    menuNoNameMap(state): BaseObject<string> {
      const recursionArr = (arr: any[]): BaseObject<string> => arr?.length
        ? arr?.reduce?.((a, v) => ({
          ...a,
          [v.name]: v.menu_no,
          ...(recursionArr(v.children))
        }), {})
        : {}
      return recursionArr(state?.allMenuData)
    },

    /**
     * [ menu_no0, menu_no1, menu_no2 ]
     * 
     * @param state 
     * @returns 
     */
    menuNoList(state): string[] {
      const recursionArr = (arr: any[]) => arr?.length
        ? arr?.reduce?.((a, v) => ([
          ...a,
          v.menu_no,
          ...recursionArr(v.children)
        ]), [] as string[])
        : []
      return recursionArr(state?.allMenuData)
    },

    /**
     * {
     *  [parent_no1]: [child_no],
     *  [parent_no2]: []
     * }
     * @param state 
     * @returns 
     */
    menuNoParentChildMap(state): BaseObject<string[]> {
      const recursionArr = (arr: any[]) => arr?.length
        ? arr?.reduce?.((a, v) => ({
          ...a,
          [v.menu_no]: v.children?.length
            ? v.children.map((cv: any) => cv.menu_no)
            : [],
          ...(recursionArr(v.children))
        }), {})
        : {}
      return recursionArr(state?.allMenuData)
    },

    /**
     * {
     *  [child_no1]: parent_no1,
     *  [child_no2]: parent_no2,
     * }
     * @param state 
     * @returns 
     */
    menuNoChildParentMap(state): BaseObject<string> {
      const recursionArr = (arr: any[]) => arr?.length
        ? arr?.reduce?.((a, v) => {
          v.children?.forEach?.((cv: any) => {
            a[cv.menu_no] = v.menu_no
          })
          return {
            ...a,
            ...recursionArr(v.children),
          };
        }, {})
        : {}
      return recursionArr(state?.allMenuData)
    },

    /**
     * [ menu_no0, menu_no1, menu_no2 ]
     * 
     * @param state 
     * @returns 
     */
    projectMenuNoList(state): string[] {
      return state?.allProjectData?.map?.(v => v.menu_no)
    },
  },

  actions: {
    async getPrmAuthMenu() {
      if (this.allProjectData?.length && this.allMenuData?.length) {
        return [this.allProjectData, this.allMenuData]
      }
      try {
        const { menus } = await getPrmAuthMenuApi()
        this.allMenuData = menus.filter((m: AuthItem) => m.menu_type === 'M')
        this.allProjectData = menus.filter((m: AuthItem) => m.menu_type === 'P')
        return [this.allProjectData, this.allMenuData]
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRolesOptions() {
      try {
        if (this.allRoleOptions?.length) {
          return this.allRoleOptions
        }
        const { list } = await getPrmRolesListApi({
          page_size: 10000,
          pageSize: 10000,
          role_name: ''
        })
        this.allRoleOptions = list?.map?.((m: PrmRoleItem) => ({
          ...m,
          label: m.role_name,
          value: m.role_no,
        }))
        return this.allRoleOptions
      } catch (error) {
        return Promise.reject(error)
      }
    },

    getFormatMenuData(disabledKeys?: string[], isFilter?: boolean): TreeProps['treeData'] {
      return generateFormatMenuData(this.asyncMenuData, this.menuNoNameMap, disabledKeys || [], isFilter) as TreeProps['treeData']
    },

    getAllMenuNoKey(disabledKeys?: string[]): [TreeProps['treeData'], string[]] {
      const data = this.getFormatMenuData(disabledKeys, true)
      const expandKeys = getExpandKeys(data)
      return [data, expandKeys]
    },

    async getMenuNoByName(name: string) {
      if (!this.allProjectData?.length) {
        await this.getPrmAuthMenu()
      }
      return this.menuNoNameMap[name]
    }
  }
});
